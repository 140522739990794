import React, {useEffect} from 'react';
import JWPlayer from '@jwplayer/jwplayer-react';
import Seo from "../components/seo";
import Layout from "../components/layout";
import {DataProvider} from "../contentful/data-provider";
import {graphql, withPrefix} from "gatsby";
import videojs from 'video.js';
import 'videojs-contrib-ads';
import 'videojs-ima';
import 'video.js/dist/video-js.css';
import 'videojs-ima/dist/videojs.ima.css';

const VastTags = ({data}) => {
    const dataProvider = new DataProvider(data);

    const vastTag = data.contentfulLandingPage?.vastTag?.vastTag

    const loadGoogleImaScript = (callback) => {
        const existingScript = document.getElementById('googleIma');

        if (!existingScript) {
            const script = document.createElement('script');
            script.src = '//imasdk.googleapis.com/js/sdkloader/ima3.js';
            script.id = 'googleIma';
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                if (callback) callback();
            };
        } else if (callback) {
            callback();
        }
    };

    useEffect(() => {
        loadGoogleImaScript(() => {
            const videoOptions = {
                controls: true,
                sources: [{
                    src: withPrefix('videos/vast-placeholder.mp4'),
                    type: 'video/mp4',
                }]
            };

            const player = videojs('content_video', videoOptions);

            const imaOptions = {
                adTagUrl: vastTag
            };

            player.ima(imaOptions);

            return () => {
                if (player) {
                    player.dispose();
                }
            };
        });
    }, [vastTag]);

    return (
        <Layout dataProvider={dataProvider}>
            <Seo dataProvider={dataProvider} title={`GlassView | Work - Sample Vast tag`}/>
            <main className={"pt-24 lg:pt-24 pb-20"}>
                <section className='pb-10 md:pb-20'>
                    <div className={"container text-center flex justify-center"}>
                        <div className={`aspect-video w-screen h-auto`}>
                            <video
                                id="content_video"
                                className="video-js vjs-big-play-centered"
                                controls
                                autoPlay
                            >
                                <p className="vjs-no-js">
                                    To view this video please enable JavaScript, and consider upgrading to a
                                    web browser that
                                    <a href="https://videojs.com/html5-video-support/" target="_blank"
                                    >supports HTML5 video</a
                                    >
                                </p>
                            </video>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    );
};

export default VastTags;

export const query = graphql`
    query LandingPageVASYBySlug($slug: String!, $locale: String) {
        contentfulLandingPage(slug: { eq: $slug }) {
            id
            slug
            title
            vastTag {
                vastTag
            }
        }
        allContentfulMenu(filter: { node_locale: { eq: $locale } }) {
            edges {
                node {
                    ...Menus
                }
            }
        }
    }
`;
